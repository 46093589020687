







import { LoyoutModule } from '@/store/modules/layout'
import { Component, Vue } from 'vue-property-decorator'
import { getQuestionDetail } from '@/pages/videoGame/api'
@Component({
  name: ''
})
export default class extends Vue {
  title = ''
  content = ''
  created() {
    const id: any = this.$route.query.id
    getQuestionDetail({ id }).then(res => {
      this.title = res.data.title
      this.content = res.data.content
      const title: any = this.$t('问题解答')
      LoyoutModule.setTitle(title)
      document.title = title
    })
  }
}
